.button-container {
  padding: 0 10px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    width: 100%;
    height: 40px;
    background-color: #3395ea;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1em;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.25s ease;

    @media(hover: hover) and (pointer: fine) {
      &:hover {
        transform: scale(1.1);
        filter: brightness(110%);
      }
    }

    .icon-left {
      margin-right: 12px;
    }

    .icon-right {
      margin-left: 12px;
    }
  }
}
